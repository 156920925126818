<template>
  <div>
    <!--<v-tour name="myTour" :steps="steps"></v-tour>
    <section class="pb-5">
      <div class="container">
        <div class="row mb-5 mb-sm-2">

          <div class="col d-flex align-items-center">
            <div class="d-inline-block p-3 p-md-4 rounded-circle">
              <img src="../assets/img/kljukica_zelena.svg" alt="Hostomate logo" class="icon">
            </div>
            <div class="ml-4">
              <h1 class="mb-2">
                Booking Dashboard
              </h1>
              <div class="lead">
                During your stay, you can unlock your room here.
              </div>
            </div>
          </div>

          <div class="col d-flex align-items-center flex-row-reverse">
            <button type="button" class="btn btn-primary btn-sm rounded-circle" title="Help?" v-on:click="$tours['myTour'].start(1)" id="help">
              <i class="fa fa-question"></i>
            </button>
          </div>

        </div>
      </div>
    </section>-->
    <section class="bg-light tab-content pt-5">
      <div class="tab-pane fade show active" id="general" role="tabpanel" aria-labelledby="general-tab">
        <div class="container">
          <div class="row">

            <div class="col-12">
              <Room v-bind.sync="{lockDisabled: lockDisabled}"></Room>
              <hr class="mt-lg-5">
            </div>


            <div class="col-12">
              <EntryCode v-bind="{entrycode: locks.entrycode, counter:counter}"></EntryCode>
              <hr class="mt-lg-5">
            </div>


            <div class="col-12">
              <div class="ml-4 mr-4">
                <h5>Breakfast</h5>
                <p>{{booking.rate.breakfast}}</p>
              </div>
              <hr class="mt-lg-5">
            </div>

            <div class="col-12">
              <div class="ml-4 mr-4">
                <h5>Bicycle garage</h5>
                <p>If you arrive with a bicycle, you can park it in our garage. Enter through the front door and use the code <strong>919363</strong> to unlock the door on the left to enter the garage. Then, open the garage door from the inside and drive the bike into the garage.</p>
              </div>
              <hr class="mt-lg-5">
            </div>

            <div class="col-12">
              <div class="ml-4 mr-4">
                <h5>Car park</h5>
                <p>You can temporarily leave your vehicle in front of the house while unloading your luggage. Afterwards, please park your vehicle in one of the available free public parking lots, as indicated in the links below:</p>
                <ul class="lst-n">
                  <li>
                    <a href="https://maps.google.com?saddr=Current+Location&daddr=46.084601,13.631478" target="_blank">Parking 1</a>
                  </li>
                  <li>
                   <a href="https://maps.google.com?saddr=Current+Location&daddr=46.086877,13.634789" target="_blank">Parking 2</a>
                  </li>
                  <li>
                    <a href="https://maps.google.com?saddr=Current+Location&daddr=46.084363,13.633999" target="_blank">Parking 3</a>
                  </li>
                </ul>
              </div>
              <hr class="mt-lg-5">
            </div>


            <div class="col-12">
              <div class="ml-4 mr-4">
                <h5 class="mb-1">More info</h5>
                <p>If you need any assistance, you can call us on the phone number:</p>
                <a v-bind:href="'tel:' + booking.property.contact.phone" class="lead">
                  {{booking.property.contact.phone}}
                </a>
              </div>
            </div>
          </div>
          <!--
          <div class="row align-items-start text-center text-md-left">
            <div class="col-md-6 order-md-2 col-lg-4 mb-4 mb-md-0 sticky-md-top d-flex justify-content-center">
              <div class="text-center">

                <div v-if="booking.property.nuki" id="property-lock">
                  <h4>Street Door</h4>
                  <div v-if="booking.property.nuki.lockId">
                    <LockButton v-bind="{button: locks.property, toggleLock: toggleLock, counter: counter }"></LockButton>
                  </div>
                  <div v-else-if="booking.property.nuki.openerId">
                    <button type="button" class="btn btn-primary" v-on:click="toggleLock(3, 'property')" :disabled="lockDisabled">
                      <i class="fa fa-bolt" aria-hidden="true"></i>
                      Open street door
                    </button>
                  </div>
                  <br>
                  <br>
                </div>

                <div v-if="booking.unit && booking.unit.nuki && booking.unit.nuki.lockId" id="unit-lock">
                  <h4>Room Door</h4>
                  <LockButton v-bind="{button: locks.room, toggleLock: toggleLock }"></LockButton>
                </div>

                <div>
                  <span v-if="counter.days > 0">
                    {{counter.days}} days
                  </span>
                  <span v-if="counter.days > 0 || counter.hours > 0">
                    {{counter.hours}} hours
                  </span>
                  <span v-if="counter.days > 0 || counter.hours > 0 || counter.minutes > 0">
                    {{counter.minutes}} minutes
                  </span>
                  <span v-if="counter.days > 0 || counter.hours > 0 || counter.minutes > 0 || counter.seconds > 0">
                    {{counter.seconds}} seconds
                  </span>
                </div>

              </div>
            </div>
            <div class="col-md-6 col-lg-8 pr-lg-5">
              <h4 class="mb-1">{{booking.property.name}}</h4>
              <address class="lead mb-lg-5 mb-4 d-inline-block" id="address-information">
                <p>{{booking.property.address.street}}</p>
                <p>
                  {{booking.property.address.postalCode}}
                  {{booking.property.address.city}}
                </p>
                <p v-if="booking.property.address.state">
                  {{booking.property.address.state}}
                </p>
                <p>{{propertyCountry.name}}</p>
              </address>
              <div class="d-md-flex flex-wrap justify-content-between justify-content-md-start" id="contact-information">
                <div class="mr-md-3 mr-lg-5 mb-4">
                  <h5 class="mb-1">Email us</h5>
                  <a v-bind:href="'mailto:' + booking.property.contact.email" class="lead">
                    {{booking.property.contact.email}}
                  </a>
                </div>
                <div class="mr-md-3 mr-lg-5 mb-4">
                  <h5 class="mb-1">Call any time</h5>
                  <a v-bind:href="'tel:' + booking.property.contact.phone" class="lead">
                    {{booking.property.contact.phone}}
                  </a>
                </div>
              </div>
            </div>
          </div>
          -->
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import io from 'socket.io-client';
import Room from './partials/Room';
import LockButton from './partials/LockButton';
import EntryCode from './partials/EntryCode';

const moment = require('moment');
const countryList = require('country-list');

import {cloneDeep} from 'lodash';

export default {
  name: 'Booking',
  data: () => ({
    steps: [
      {
        target: '#help',
        header: {
          title: 'Need help?',
        },
        content: `Discover <strong>Hostomate Tour</strong>!`,
        params: {
          placement: 'bottom'
        }
      }
    ],
    lockDisabled: true,
    countries: countryList.getData(),
    booking: {},
    counter: {
      days: 0,
      hours: 0,
      minutes: 0,
      seconds: 0
    },
    locks: {
      room: {
        disabled: true,
        state: "",
        action: "",
        title: "Loading ...",
        doorState: "",
        class: "fa fa-spinner fa-spin",
        type: 'room',
      },
      property: {
        disabled: true,
        state: "",
        action: "",
        title: "Loading ...",
        doorState: "",
        class: "fa fa-spinner fa-spin",
        type: 'property'
      },
      entrycode: {
        title: "",
        class: ""
      }
    }
  }),
  components: {
    Room, LockButton, EntryCode
  },
  methods: {
    toggleLock: function(action, type){
      if(action == '1' || action == '2'){
        this.locks[type].class = "fa fa-spinner fa-spin";
        this.locks[type].disabled = true;
      }
      return fetch( process.env.VUE_APP_NUKI + '/opener', {
        method : 'post',
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json',
          'CSRF-Token': this.$csrf
        },
        body: JSON.stringify({
          "action": action,
          "booking": this.booking._id,
          "type": type,
          "_id": this.booking[type]._id,
          'unitId': this.booking.unitId
        })
      })
      .then(response => response.json())
      .then(response => {
        console.log(response);
      });
    },
    setCounter: function(){
      let now = moment();
      let checkInTime = moment(this.booking.night.first + ' ' + this.booking.property.time.checkIn);
      let diff = checkInTime.diff(now);
      if(diff > 0){
        let duration = moment.duration(diff);

        this.lockDisabled = true;

        this.counter.days = duration.days();
        this.counter.hours = duration.hours();
        this.counter.minutes = duration.minutes();
        this.counter.seconds = duration.seconds();

        this.locks.room.disabled = true;
        this.locks.room.title = "Door lock will be enabled in time of your booking.";
        this.locks.room.class= "fa fa-lock";

        this.locks.property.disabled = true;
        this.locks.property.title = "Door lock will be enabled in time of your booking.";
        this.locks.property.class= "fa fa-lock";

        this.locks.entrycode.title = this.$store.state.booking.nuki.keypadCode

        setTimeout(this.setCounter, 1000);
      }else{
        this.lockDisabled = false;
        this.locks.entrycode.title = this.$store.state.booking.nuki.keypadCode

      }
    }
  },
  created() {
    this.booking = this.$store.state.booking;
  },
  mounted() {
    console.log(moment());
    console.log(this.booking.property.time.checkIn);
    this.socket = io(process.env.VUE_APP_NUKI);

    this.socket.on('connect', () => {
      this.setCounter();
      this.socket.emit('authentication', {bookingId: this.booking._id });
    });

    this.socket.on('authenticated', function() {
      console.log("authenticated socket")
    });

    this.socket.on('disconnect', (reason) => {
      console.log(`Disconnected: ${reason}`);
      this.lockDisabled = true;

      this.locks.room.disabled = true;
      this.locks.room.state = "";
      this.locks.room.action = "";
      this.locks.room.title = "No connection to the server";
      this.locks.room.doorState = "";
      this.locks.room.class = "fa fa-spinner fa-spin";

      this.locks.property.disabled = true;
      this.locks.property.state = "";
      this.locks.property.action = "";
      this.locks.property.title = "No connection to the server";
      this.locks.property.doorState = "";
      this.locks.property.class = "fa fa-spinner fa-spin";
    });

    this.socket.on('lockStatus', (response) => {
      let lock;
      let data = JSON.parse(response);
      if(this.booking.unit && this.booking.unit.nuki && this.booking.unit.nuki.lockId == data.lockId){
        lock = this.locks.room;
      }
      if(!lock && this.booking.property.nuki && this.booking.property.nuki.lockId == data.lockId){
        lock = this.locks.property;
      }
      if(!lock) return;

      if(this.lockDisabled){
        return;
      }

      if(data.state != lock.state || data.doorState != lock.doorState){
        lock.state = data.state;
        lock.doorState = data.doorState;
        if(lock.state == '0'){
          lock.title = 'Uncalibrated';
          lock.action = '1';
          lock.class="fa fa-lock";
          lock.disabled = false;
        }else if(lock.state == '1'){
          lock.title = 'Locked';
          lock.action = '1';
          lock.class = "fa fa-lock";
          lock.disabled = false;
        }else if(lock.state == '2'){
          lock.title = 'Unlocking';
          lock.action = '1';
          lock.class="fa fa-spinner fa-spin";
          lock.disabled = true;
        }else if(lock.state == '3'){
          lock.title = 'Unlocked';
          lock.action = '2';
          lock.class="fa fa-unlock";
          lock.disabled = false;
        }else if(lock.state == '4'){
          lock.title = 'Locking';
          lock.action = '2';
          lock.class="fa fa-spinner fa-spin";
          lock.disabled = true;
        }else if(lock.state == '5'){
          lock.title =  'Unlatched';
          lock.disabled = false;
        }else if(lock.state == '6'){
          lock.title = Unlocked
          lock.disabled = false;
        }else if(lock.state == '7'){
          lock.title = 'Unlatching'
          lock.disabled = false;
        }else if(lock.state == '254'){
          lock.title = 'Motor blocked';
          lock.disabled = false;
        }else{
          lock.title = 'Undefined';
          lock.disabled = false;
        }

        if(lock.doorState == '1'){
          lock.title += ' - Door sensor deactivated';
        }else if(lock.doorState == '2'){
          lock.title += ' - Door closed';
        }else if(lock.doorState == '3'){
          lock.title += ' - Door opened';
        }else if(lock.doorState == '4'){
          lock.title += ' - Door sensor state unknown';
        }else if(lock.doorState == '5'){
          lock.title += ' - Door sensor calibrating';
        }else{
          lock.title += ' - Undefined';
        }
      }
    });

    if(this.$store.state.booking.unit){
      this.steps.push({
        target: '#unit',
        content: `This is a name of a unit where you are staying!`,
        params: {
          placement: 'top'
        }
      })
    }

    this.steps.push({
      target: '#booking-date',
      content: `This is a date of your booking!`,
      params: {
        placement: 'top'
      }
    })


    if(this.$store.state.booking.nuki && this.$store.state.booking.nuki.keypadCode){
      this.steps.push({
        target: '#entry-code',
        content: `This is an entry code for unlocking unit and street door!`,
        params: {
          placement: 'top'
        }
      })
    }

    if(this.booking.property.nuki && (this.booking.property.nuki.lockId || this.booking.property.nuki.openerId)){
      this.steps.push({
        target: '#property-lock',
        content: `You can unlock street door by clicking this button!`,
        params: {
          placement: 'top'
        }
      })
    }

    if(this.booking.unit && this.booking.unit.nuki && this.booking.unit.nuki.lockId){
      this.steps.push({
        target: '#unit-lock',
        content: `You can unlock unit door by clicking this button!`,
        params: {
          placement: 'top'
        }
      })
    }

    this.steps.push({
      target: '#address-information',
      content: `This is an address of the property where you are staying!`,
      params: {
        placement: 'top'
      }
    })

    this.steps.push({
      target: '#contact-information',
      content: `If you have any questions questions, you can contact your host vie email or phone!`,
      params: {
        placement: 'bottom'
      }
    })

    /*this.$tours['myTour'].start();*/
  },
  computed: {
    propertyCountry: function(){
      return this.countries.find(e => e.code == this.booking.property.address.country);
    }
  },
}
</script>
