<template>
<div class="ml-4 mr-4">
  <h4><span>Entry Code: </span>{{ entrycode.title }}</h4>
  <p>The code is valid for street doors and room doors.</p>
  <p>From the outside, you can lock the door by pressing the back arrow key.</p>
  <p>From the inside, you can lock the door by simply turning the rounded part of the lock attached to the door.</p>
  <img src="../../assets/img/lock-unlock.png" alt="Hostomate logo">

</div>
</template>

<script>

export default {
  name: 'EntryCode',
  props: ['entrycode'],
}
</script>
