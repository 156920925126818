<template>
  <div>
    <button class="btn btn-lg btn-primary rounded-circle" :disabled="button.disabled" v-on:click="toggleLock(button.action, button.type)">
      <i v-bind:class="button.class" aria-hidden="true"></i>
    </button>
    <br>
    <h5>{{button.title}}</h5>
  </div>
</template>

<script>

export default {
  name: 'LockButton',
  props: ['button', 'toggleLock'],
}
</script>
